.product-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8); /* Dark semi-transparent background */

    .product-modal__content {
        width: 100%; /* Take full width of screen */
        height: 100vh; /* Full viewport height */
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999;
        padding: 10px;
        position: relative;

        .product-modal__img {
            max-width: 100%;
            max-height: 100vh;
            object-fit: contain;
        }

        .product-modal__close {
            position: absolute;
            top: 2rem;
            right: 2rem;
            cursor: pointer;
            width: 20px;
            height: 20px;
            z-index: 1000; /* Make sure close button is above everything */
        }
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.699);
        z-index: 1; /* Below content */
    }

    .preview-image {
        object-fit: contain;
        cursor: pointer;
    }

    @media (min-width: 1440px) {
        .product-modal__content {
            .product-modal__close {
                top: 2rem;
                right: 2rem;
                cursor: pointer;
                width: 25px;
                height: 25px;
            }
        }
    }
}
