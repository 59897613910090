.about {
  padding-top: 5rem;
  .section {
    padding: 0 2rem;
  }
  .about__text {
    margin-bottom: 7.2rem;
    width: 100%;
  }
  p {
    font-size: 1.2rem;
    line-height: 2rem;
    font-weight: 600;
    color: #242424;
    margin-bottom: 0.8rem;
    font-weight: 400;
    span {
      color: #ea5544;
    }
  }
  .about-youtube__video {
    display: flex;
    justify-content: center;
    margin-bottom: 6rem;
    iframe {
      max-width: 350px;
      width: 100%;
      height: 550px;
    }
  }
  .about__info {
    margin-bottom: 2.2rem;
    max-width: 907px;
    p {
      color: #474747;
    }
  }
  .about__info-steps {
    display: grid;
    justify-content: center;
    gap: 1.5rem;
    margin-bottom: 4rem;
    .info-step {
      background-color: #fff;
      box-shadow: 0px 3px 6px 0px rgba(212, 212, 212, 0.1),
        0px 10px 10px 0px rgba(212, 212, 212, 0.09),
        0px 23px 14px 0px rgba(212, 212, 212, 0.05),
        0px 41px 16px 0px rgba(212, 212, 212, 0.01),
        0px 64px 18px 0px rgba(212, 212, 212, 0);
      padding: 3rem 2.2rem;
      max-width: 450px;
      h3 {
        font-size: 4rem;
        margin-bottom: 2rem;
        color: #ea5544;
      }
      p {
        color: #474747;
        font-weight: 500;
      }
    }
  }
  .about__info_additional {
    max-width: 450px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    margin-bottom: 8rem;
    font-size: 1.2rem;
    line-height: 2.04rem;
    p {
      color: #474747;
      font-weight: 600;
    }
    .more_info {
      font-size: 1.4rem;
      line-height: 2rem;
      font-weight: 700;
      color: #fff;
      background-color: #ea5544;
      padding: 2.3rem 0;
      text-align: center;
    }
  }
  .about__events {
    margin-bottom: 8rem;
    .videos-carousel {
      .section {
        padding: 0;
      }
    }
  }
  .mySwiper {
    .swiper-wrapper {
      display: flex;
      align-items: center;
      margin-right: 50px;
      .swiper-slide {
        max-width: 420px;
        height: 420px;
        transition: all 0.3s;
        img {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }
      .swiper-slide-next,
      .swiper-slide-prev {
        height: 303px;
      }
    }
  }
  .about__gallery {
    display: none;
  }
  @media (min-width: 768px) {
    .section {
      padding: 0 3rem;
    }
    .about__info-steps {
      grid-template-columns: repeat(2, minmax(306px, 1fr));
      justify-content: center;
      .info-step {
        max-width: 100%;
      }
    }
    .about__info_additional {
      max-width: 729px;
      align-items: flex-start;
      margin-left: 0;
      .more_info {
        padding: 2rem 6.1rem;
      }
    }
    .mySwiper {
      display: none;
    }
    .about__gallery {
      display: grid;
      gap: 2.4rem;
      justify-content: center;
      grid-template-columns: repeat(auto-fit, minmax(306px, 1fr));
      grid-auto-rows: 257px;
      grid-auto-flow: dense;
      margin-bottom: 8rem;
      .horizontal {
        grid-column: span 2;
      }
      > div {
        overflow: hidden;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
        transition: all 150ms linear;
      }
    }
  }
  @media (min-width: 930px) {
    padding-top: 10rem;
  }
  @media (min-width: 1024px) {
    .section {
      padding: 0 rem;
    }
    p {
      font-size: 1.5rem;
      line-height: 2.5rem;
      margin-bottom: 1rem;
    }
    .about__text {
      margin-bottom: 8.8rem;
    }
    .about__info {
      margin-bottom: 4.8rem;
    }
    .about__info-steps {
      gap: 2.4rem;
      margin-bottom: 6rem;
      P {
        font-size: 1.3rem;
        line-height: 2.1rem;
      }
    }
    .about__info_additional {
      gap: 4rem;
      margin-bottom: 10rem;
      p {
        font-size: 1.5rem;
        line-height: 2.5rem;
        font-weight: 500;
      }
      .more_info {
        font-size: 1.4rem;
        line-height: 2.1rem;
        font-weight: 500;
        transition: all 120ms linear;
        border: 1px solid #ea5544;
        &:hover {
          background-color: #fff;
          color: #ea5544;
        }
      }
    }
    .about__gallery {
      margin-bottom: 10rem;
    }
  }
  @media (min-width: 1440px) {
    .about__text {
      margin-bottom: 13.2rem;
    }
    .about__info-steps {
      grid-template-columns: repeat(4, minmax(306px, 1fr));
      .info-step {
        max-width: 306px;
      }
    }
    .about__info_additional {
      margin-bottom: 13.2rem;
    }
    .about__gallery {
      margin-bottom: 14rem;
    }
  }
}
