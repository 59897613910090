.home-contacts {
	margin-bottom: 8rem;
	.section {
		padding: 0 2rem;
	}
	.home-contacts__content {
		padding: 3rem 1.6rem;
		background-color: #fff;
		box-shadow: 0px 3px 6px 0px rgba(212, 212, 212, 0.1),
			0px 10px 10px 0px rgba(212, 212, 212, 0.09),
			0px 23px 14px 0px rgba(212, 212, 212, 0.05),
			0px 41px 16px 0px rgba(212, 212, 212, 0.01),
			0px 64px 18px 0px rgba(212, 212, 212, 0);
		display: flex;
		flex-direction: column;
		gap: 4.6rem;
		.home-contacts__left {
			.subtitle-large {
				display: none;
			}
			form {
				display: flex;
				flex-direction: column;
				gap: 1.2rem;
				margin-bottom: 1.2rem;
				input {
					font-size: 1.6rem;
					line-height: 2rem;
					padding: 2.3rem 2.7rem;
					border: 1px solid #ededed;
					outline: none;
					&::placeholder {
						color: #9b9b9b;
						font-size: 1.2rem;
					}
				}
				button {
					font-size: 1.32rem;
					line-height: 1.6rem;
					font-weight: 700;
					color: #fff;
					background-color: #ea5544;
					border: 1px solid #ea5544;
					padding: 2.2rem 9.95rem;
				}
			}
			p {
				font-size: 1.1rem;
				line-height: 1.406rem;
				color: #9b9b9b;
				text-align: center;
				padding: 0 2.35rem;
			}
		}
		.home-contacts__right {
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			.globe {
				width: 100%;
				max-width: 447px;
			}
		}
	}
	@media (min-width: 640px) {
		form {
			max-width: 600px;
			margin: 0 auto;
			gap: 2rem;
		}
	}
	@media (min-width: 768px) {
		.section {
			padding: 0 3rem;
		}
		h2 {
			margin-bottom: 3rem;
		}
	}
	@media (min-width: 920px) {
		.container {
			.subtitle {
				display: none;
			}
			.home-contacts__content {
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				.home-contacts__left {
					.subtitle-large {
						display: inline-block;
						margin-bottom: 3rem;
						z-index: 1;
					}
					form {
						gap: 2.5rem;
						max-width: 820px;
					}
				}
			}
		}
	}
	@media (min-width: 1024px) {
		.section {
			padding: 0 4rem;
		}
		.home-contacts__left {
			form {
				button {
					transition: all 150ms linear;
					&:hover {
						cursor: pointer;
						background-color: transparent;
						color: #ea5544;
					}
				}
			}
		}
	}
	@media (min-width: 1440px) {
		margin-bottom: 14rem;
		.home-contacts__content {
			padding: 0 0 0 5rem;
		}
		.home-contacts__content {
			.home-contacts__left {
				form {
					flex-direction: row;
					gap: 1.7rem;
					margin-bottom: 1.7rem;
					input {
						width: 223px;
						padding: 2rem 3rem 2rem 1rem;
					}
					button {
						width: 223px;
						display: flex;
						align-items: center;
						justify-content: center;
						padding: 2rem 5rem;
						&:hover {
							cursor: pointer;
							background-color: transparent;
							color: #ea5544;
						}
					}
				}
				p {
					text-align: left;
					padding: 0;
				}
			}
		}
	}
}