.reviews-carousel {
	.section {
		padding: 0 2rem;

		.reviews {
			margin: 0 auto;
		}
	}

	@media (min-width: 768px) {
		.section {
			padding: 0 3rem;
		}
	}

	@media (min-width: 1024px) {
		.section {
			padding: 0 4rem;
		}
	}
}
