.catalog {
  padding-top: 5rem;
  padding-bottom: 8rem;
  .section {
    padding: 0 2rem;
  }
  @media (min-width: 768px) {
    .section {
      padding: 0 3rem;
    }
  }
  @media (min-width: 1024px) {
    padding-top: 10rem;
    padding-bottom: 10rem;
    .section {
      padding: 0 4rem;
    }
  }
  @media (min-width: 1440px) {
    padding-bottom: 14rem;
  }
}
