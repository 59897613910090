.product-card {
    max-height: 450px;
    max-width: 420px;
    background: #fff;
    box-shadow: 0 3.3px 6.5px 0 rgba(212, 212, 212, 0.1),
        0 11px 11px 0 rgba(212, 212, 212, 0.09),
        0 25px 15px 0 rgba(212, 212, 212, 0.01),
        0 45px 17.5px 0 rgba(212, 212, 212, 0.01),
        0 70px 20px 0 rgba(212, 212, 212, 0);
    padding: 3rem 2.4rem 1.7rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    .card__img-wrapper {
        margin-bottom: 0.8rem;
        overflow: hidden;
        border-radius: 6px;
        height: 270px;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .card__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        h3 {
            font-size: 1.4rem;
            line-height: 1.789rem;
            font-weight: 700;
            color: #242424;
            margin-bottom: 0.8rem;
            max-height: 3.6rem;
            overflow: hidden;
        }
        .card__footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: auto;
            .product__price {
                font-size: 2rem;
                line-height: 2.556rem;
                font-weight: 800;
                color: #ea5544;
            }
            .card__btn {
                font-size: 1rem;
                line-height: 1.278rem;
                font-weight: 600;
                color: #9b9b9b;
                display: flex;
                align-items: center;
                gap: 11px;
            }
        }
    }
    @media (min-width: 1024px) {
        padding: 2.2rem 2.2rem 2.8rem;
        transition: all 120ms linear;
        .card__img-wrapper {
            margin-bottom: 0rem;
            img {
                border-radius: 5px;
            }
        }
        .card__content {
            h3 {
                font-size: 1.3rem;
                line-height: 1.9rem;
                margin-bottom: 1rem;
                max-height: 4rem;
                overflow: hidden;
            }
            .card__footer {
                .product__price {
                    font-size: 1.5rem;
                    line-height: 2.8rem;
                }
                .card__btn {
                    font-size: 1.2rem;
                    line-height: 1.5rem;
                }
            }
        }
        &:hover {
            cursor: pointer;
            box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
            .card__btn {
                span {
                    transition: all 120ms linear;
                    color: #ea5544;
                }
                .card__arrow {
                    fill: #ea5544;
                }
            }
        }
    }
}
