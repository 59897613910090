.search-mobile {
	display: none;
	justify-content: space-between;
	align-items: center;
	gap: 2rem;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 2.9rem 2rem;
	background-color: #eaeaea;
	box-shadow: 0px 3px 6px 0px rgba(212, 212, 212, 0.1),
		0px 10px 10px 0px rgba(212, 212, 212, 0.09),
		0px 23px 14px 0px rgba(212, 212, 212, 0.05),
		0px 41px 16px 0px rgba(212, 212, 212, 0.01),
		0px 64px 18px 0px rgba(212, 212, 212, 0);

	transition: all 150ms linear;

	.search-bar {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 1rem;
		background-color: #fff;
		padding: 1.7rem 2.5rem;
		border: 1px solid #eaeaea;
		border-radius: 4px;
		flex: 1;

		input {
			border: none;
			outline: none;
			font-size: 1.1rem;
			line-height: 1.4rem;
			color: #9b9b9b;
			width: 100%;
		}
	}
}

.show-search-mobile {
	display: flex;
}
