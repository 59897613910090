figure {
	position: relative;
	overflow: hidden;
	border-radius: 5px;
	width: 100%;
	height: 100%;
	figcaption {
		position: absolute;
		bottom: 3rem;
		left: 50%;
		transform: translateX(-50%);
		text-align: center;
		font-size: 1rem;
		line-height: 1.8rem;
		font-weight: 600;
		color: #fff;
		text-transform: uppercase;
		width: 100%;
		padding: 0 10px;
	}
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
@media (min-width: 1024px) {
	figure {
		figcaption {
			left: 3rem;
			transform: translateX(0%);
			text-align: left;
			font-size: 1.2rem;
		}
	}
}
@media (min-width: 1440px) {
	.vertical {
		grid-row: span 2;
	}
	.horizontal {
		grid-column: span 2;
	}
}