@font-face {
  font-family: "MontBlack";
  src: url("./fonts/MontBlack.ttf");
}
html {
  font-size: 10px;
  font-family: Montserrat, serif;
  background-color: #d4d4d410;
  scroll-behavior: smooth;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 90%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.subtitle {
  font-size: 2.2rem;
  line-height: 2.812rem;
  font-weight: 900;
  color: #474747;
  margin-bottom: 2rem;
  display: inline-block;
  text-transform: uppercase;
  position: relative;
  z-index: -1;
  &:before {
    content: "";
    position: absolute;
    width: 65%;
    height: 3px;
    background-color: #ea5544;
    top: -4.5px;
    left: 0;
  }
  @media (min-width: 1024px) {
    font-size: 2.7rem;
    line-height: 3.4rem;
    margin-bottom: 3rem;
  }
  @media (min-width: 1440px) {
    font-size: 3rem;
    line-height: 3.834rem;
    margin-bottom: 5.6rem;
  }
}
a {
  color: #fff;
  text-decoration: none;
}
.section {
  width: 100%;
  max-width: 375px;
  margin: 0 auto;
}
.container {
  width: 100%;
  max-width: 335px;
  margin: 0 auto;
}
@media (min-width: 375px) {
  .section {
    width: 100%;
    max-width: 768px;
    margin: 0 auto;
  }
  .container {
    width: 100%;
    max-width: 708px;
    margin: 0 auto;
  }
}
@media (min-width: 768px) {
  .section {
    max-width: 1024px;
  }
  .container {
    max-width: 944px;
  }
}
@media (min-width: 1024px) {
  .section {
    max-width: 1440px;
  }
  .container {
    max-width: 1296px;
  }
}
.response-message {
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: #242425;
  text-align: center;
  font-weight: 400;
}
.navigation_mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  gap: 3.2rem;
  button {
    border: none;
    transition: all 100ms linear;
    background-color: #ffff;
    &:active {
      transform: scale(0.9);
    }
    &.swiper-button-disabled {
      opacity: 0.6;
    }
  }
  .prev_btn {
    img {
      transform: rotate(180deg);
    }
  }
}
