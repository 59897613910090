.news-grid {
	margin-bottom: 8rem;
	.section {
		padding: 0 2rem;
	}
	.news-grid__small {
		display: grid;
		// align-items: start;
		grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
		gap: 2rem;
		.news__card {
			display: flex;
			flex-direction: column;
			border-radius: 9px;
			padding: 3rem 2.4rem;
			background-color: #fff;
			box-shadow: 0px 3px 6px 0px rgba(212, 212, 212, 0.1),
				0px 10px 10px 0px rgba(212, 212, 212, 0.09),
				0px 23px 14px 0px rgba(212, 212, 212, 0.05),
				0px 41px 16px 0px rgba(212, 212, 212, 0.01),
				0px 64px 18px 0px rgba(212, 212, 212, 0);
			.img-wrapper {
				width: 100%;
				height: 217px;
				border-radius: 9px;
				margin-bottom: 1.6rem;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					border-radius: 9px;
				}
			}
			.news__card_content {
				// margin-top: auto;
				display: flex;
				flex-direction: column;
				>span {
					display: block;
					font-size: 1rem;
					line-height: 1.278rem;
					font-weight: 700;
					color: #9b9b9b;
					margin-bottom: 0.2rem;
				}
				h3 {
					font-size: 1.4rem;
					line-height: 1.789rem;
					font-weight: 700;
					color: #242424;
					margin-bottom: 0.7rem;
					max-height: 3.9rem;
					overflow: hidden;
				}
				p {
					font-size: 1.2rem;
					line-height: 1.534rem;
					font-weight: 600;
					color: #9b9b9b;
					margin-bottom: 1.478rem;
					height: 3.2rem;
					overflow: hidden;
				}
				.news__card_footer {
					margin-top: auto;
					display: flex;
					align-items: center;
					gap: 1.1rem;
					font-size: 1.1rem;
					line-height: 1.4rem;
					font-weight: 600;
					color: #ea5544;
					img {
						width: 17px;
					}
				}
			}
		}
	}
	@media (min-width: 768px) {
		.section {
			padding: 0 3rem;
		}
	}
	@media (min-width: 1024px) {
		margin-bottom: 10rem;
		.section {
			padding: 0 4rem;
		}
	}
	@media (min-width: 1440px) {
		margin-bottom: 14rem;
	}
}