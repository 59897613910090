.news {
  padding-top: 5rem;

  .section {
    padding: 0 2rem;
  }

  @media (min-width: 768px) {
    .section {
      padding: 0 3rem;
    }
  }

  @media (min-width: 930px) {
    padding-top: 10rem;
  }

  @media (min-width: 1024px) {
    .section {
      padding: 0 4rem;
    }

    h2 {
      margin-bottom: -1rem;
    }
  }

  @media (min-width: 1440px) {
    .section {
      padding: 0 4rem;
    }

    h2 {
      margin-bottom: 1.6rem;
    }
  }
}
